import React from 'react';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';

import CardListContainer from '../../commons/ui/CardListContainer';

const data = [
  [
    {
      title: "Nos autos",
      text: "Vous songez à acquérir une voiture neuve au meilleur prix? Commandez-la sur Autosource et économisez jusqu’à 30%",
      link: "https://fr.auto-source.ch/"
    },
    {
      title: "Nos scooters",
      text: "Découvrez la gamme de véhicules électriques NIU et laissez nous prendre en charge la commande ainsi que la livraison",
      link: "https://www.niu.com/fr/"
    }
  ]
];

function Sales() {
  return (
    <div>
      <FullWidthBgImageContainer bgClassname="bg-img-sales">
        <div className="contentTitleWrapper">
          <h1>Ventes de véhicules neufs</h1>
          <h2>Nous vendons des véhicules neufs de toutes marques.</h2>
        </div>
      </FullWidthBgImageContainer>

      <CardListContainer data={data}>
        <h3>Nos véhicules neufs</h3>
        <p>Au travers des liens ci-dessous, vous pourrez acquérir une voiture neuve à un prix jusqu’à 30% inférieur au prix catalogue (importation). La nouvelle gamme de véhicules électriques NIU est aussi disponible. Dans tous les cas, nous prenons en charge la commande ainsi que la livraison.</p>
        <p>N’hésitez pas à prendre contact avec nous via l’adresse e-mail ou le numéro de téléphone mentionnées ci-dessous.</p>
      </CardListContainer>

      <FullWidthButtonContainer />

    </div>
  );
}

export default Sales;
