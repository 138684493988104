import React from 'react';
import { Container } from 'react-bootstrap';

function FullWidthBgImageContainer({children, bgClassname}) {
  let bg = "bg-img";
  if (bgClassname) {
    bg += " " + bgClassname;
  }

  return (
    <Container fluid className={"contentWrapper shadow-inset " + bg}>
      <Container>
        { children }
      </Container>
    </Container>
  );
}

export default FullWidthBgImageContainer;
