import React from 'react';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';
import CardListContainer from '../../commons/ui/CardListContainer';

import ServicesTextSection from './ServicesTextSection';

import CardsData from './data/Cards';

function Services() {
  return (
    <div>
      <FullWidthBgImageContainer bgClassname="bg-img-services">
        <div className="contentTitleWrapper">
          <h1>Services</h1>
          <h2>Réservez en ligne tous services liés à votre auto, moto ou scooter. Vous pouvez aussi nous contacter à tous moments via l’adresse mail ou le numéro de téléphone présents ci-dessous.</h2>
        </div>
      </FullWidthBgImageContainer>

      <ServicesTextSection />
      <FullWidthButtonContainer />
      <CardListContainer data={CardsData} />
    </div>
  );
}

export default Services;
