import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

function ServicesTextSection(props) {
  return (
    <Container className="contentWrapper">
      <h3>Vous servir est notre priorité</h3>
      <p> Que ce soit pour votre voiture, votre moto ou votre scooter, nous offrons une vaste gamme de services visant à garantir la sécurité ainsi que la longévité de votre véhicule.</p>
      <h3>Vos avantages</h3>
      <Row>
        <Col xs={12} md={6}>
          <ul>
            <li>Une flexibilité sans pareil : la taille de notre structure nous permet de nous adapter à vos besoins et de réagir rapidement</li>
            <li>Un accueil chaleureux en tout temps ; votre sécurité est notre priorité, c’est pourquoi il n’y a pas de « petit » problème. Vous serez le bienvenu chez nous et tout problème sera pris au sérieux.</li>
          </ul>
        </Col>
        <Col xs={12} md={6}>
          <ul>
            <li>Un service personnalisé de qualité ; puisque personne ne connaît mieux votre véhicule que vous-mêmes, nous sommes à votre écoute en tout temps.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default ServicesTextSection;
