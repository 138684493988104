import React from 'react';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';
import AboutUs from '../../commons/ui/AboutUs';

import ContactAddressSection from './ContactAddressSection';

function Contact(props) {
  return (
    <div>
      <FullWidthBgImageContainer bgClassname="bg-img-contact">
        <div className="contentTitleWrapper">
          <h1>Contact</h1>
          <h2>N’hésitez pas à nous contacter via l’adresse email ou le numéro de téléphone présents ci-dessous. Vous avez aussi la possibilité de prendre rendez-vous en ligne en cliquant sur le bouton ci-dessous</h2>
        </div>
      </FullWidthBgImageContainer>
      <ContactAddressSection />
      <FullWidthButtonContainer />
      <AboutUs className="contentWrapper bg-img" />
    </div>
  );
}

export default Contact;
