const CardsData = [
  [
    {
      title: '1 2 3 pneus',
      link: 'https://www.123pneus.ch/cgi-bin/rshop.pl?dsco=126&cart_id=&s_p=index&partner=1&Country=CH&ID=sea_rd_ch_Google_Search_Brand&s_kwcid=AL!432!3!426382516796!e!!g!!123%20pneus&ef_id=CjwKCAjw7--KBhAMEiwAxfpkWI9oEIJNQgB2DhhO7PVGL_w7kV9u7DujplAzjmcYlmFmg4D172vLNRoCeHgQAvD_BwE:G:s&s_kwcid=AL!432!3!426382516796!e!!g!!123%20pneus&gclid=CjwKCAjw7--KBhAMEiwAxfpkWI9oEIJNQgB2DhhO7PVGL_w7kV9u7DujplAzjmcYlmFmg4D172vLNRoCeHgQAvD_BwE'
    },

    {
      title: 'ReifenDirekt',
      link: 'https://www.reifendirekt.ch'
    },
    {
      title: 'Pneu online ',
      link: 'https://www.pneus-online-suisse.ch/?partnerDomain=adwords.google.ch_pneu-onlineFR&xtor=SEC-40-GOO-[FR-_Pneus_Online]--S-[pneu-online]&xts=223987&gclid=CjwKCAjw7--KBhAMEiwAxfpkWGRQ15oQJbn4uzWQf7MhmgOR8jRUdMezz9DBsDTHvPIg9bz8MDcnUxoCFmQQAvD_BwE'
    },
    {
      title: 'Pneuexperte ',
      link: 'https://www.pneuexperte.ch/fr/?et_cid=1&et_lid=1&etcc_med=sea&etcc_cmp=bs_sea&etcc_ctv=google&utm_source=google&utm_medium=cpc&utm_campaign=adwords&gclid=CjwKCAjw7--KBhAMEiwAxfpkWACetFBNbq-dOHrUUOEVKqGzyMg-GwrO-ql0gbRuCFG2RydWdTVuGxoC5VcQAvD_BwE&gclsrc=aw.ds'
    },
  ],
  [
    {
      title: 'CentralePneus ',
      link: 'https://www.centralepneus.ch/?gclid=CjwKCAjw7--KBhAMEiwAxfpkWKgTy7mBCgV8qGzSFIOQjmjimqv9IgfDvS8KLcOFk8jUKrzYVKxYiRoCuAwQAvD_BwE'
    }
  ]
];

export default CardsData;
