import React from 'react';

function HoursBlock() {
  return (
    <div className="addressBlockSection">
      <h3>Horaires</h3>
      <p>Lun-Ven: 07:30 - 12:00, 13:30 - 18:30</p>
      <p>Sam: 09:00 - 12:00</p>
      <p>Dim: Fermé</p>
    </div>
  );
}

export default HoursBlock;
