const CardsData = [
  [
    {
      title: 'Entretien',
      text: 'Entretien toutes marques sur vos autos, motos et scooters.'
    },
    {
      title: 'Montages et commande de pneus',
      text: 'Nous nous occupons du montage de vos pneus hiver comme été. Vous avez aussi la possibilité de les livrés faire directement chez nous pour la pose.'
    },
    {
      title: 'Expertise',
      text: 'Nous prenons en charge le remise en état de votre véhicule afin de passer la visite. La visite est elle aussi prise en charge.'
    }
  ],
  [
    {
      title: 'Dépôt-vente',
      text: 'Confiez-nous la vente de votre véhicule et laissez-nous nous charger du reste. Peu importe votre véhicule, nous estimons sa valeur et prenons en charge tout le processus de vente.'
    },
    {
      title: 'Consulting lors de la vente',
      text: 'Vous souhaitez vendre votre véhicule (auto, moto ou scooter) mais n’êtes pas certain de sa valeur exacte ? Recevez une estimation officielle de sa valeur par un mécanicien expérimenté et vendez votre véhicule au meilleur prix. '
    },
    {
      title: 'Contrôle voyage',
      text: 'Vous prévoyez un long voyage dans les semaines à venir ? Mieux vaut éviter tout imprévu, ne laissez donc rien au hasard et assurez-vous que tout roule pour votre véhicule. Nous nous assurons que votre véhicule est en parfait état de marche en moins de 20 minutes.'
    }
  ]
];

export default CardsData;
