import Home from '../views/home/Home';
import SecondHand from '../views/secondHand/SecondHand';
import Rental from '../views/rental/Rental';
import Sales from '../views/sales/Sales';
import Services from '../views/services/Services';
import Partners from '../views/tirePartners/Partners';
import Contact from '../views/contact/Contact';

const Routes = [
  {
    path: "/",
    label: "Home",
    component: Home
  },
  {
    path: "/occasions",
    label: "Occasions",
    component: SecondHand
  },
  {
    path: "/location",
    label: "Location",
    component: Rental
  },
  {
    path: "/ventes",
    label: "Vente",
    component: Sales
  },
  {
    path: "/services",
    label: "Services",
    component: Services
  },
  {
    path: "/partenaires",
    label: "Partenaires",
    component: Partners
  },
  {
    path: "/contact",
    label: "Contact",
    component: Contact
  }
];

export default Routes;
