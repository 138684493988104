const CardsData = [
  [
    {
      title: 'Occasions',
      text: 'Vous trouverez ci-dessous tous nos véhicules d’occasion disponibles à la vente. Ceci comprend les autos, motos et scooters.',
      link: '/occasions'
    },
    {
      title: 'Location',
      text: 'Ici vous pourrez louer motos ou scooters pour la durée qui vous convient. Plusieurs modèles sont à votre disposition en tout temps.',
      link: '/location'
    },
    {
      title: 'Vente',
      text: 'Nous vendons des véhicules neufs de toutes marques. Vous pourrez acquérir une voiture neuve à un prix jusqu’à 30% inférieur au prix catalogue',
      link: '/ventes'
    }
  ],
  [
    {
      title: 'Services',
      text: 'Réservez en ligne tous services liés à votre auto, moto ou scooter.',
      link: '/services'
    },
    {
      title: 'Contact',
      text: "Une question? Une requête? N'hésitez pas à nous contacter, vous avez aussi la possibilité de prendre rendez-vous en ligne",
      link: '/contact'
    }
  ]
];

export default CardsData;
