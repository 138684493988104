import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';

function CardListContainer({data, children}) {
  const history = useHistory();

  let linkEl = (card) => {

    if (!card.link) {
      return <span>{card.title}</span>;
    } else if (card.link.indexOf('/') === 0) {
      return <a href={card.link}>{card.title}</a>;
    }
    return <a href={card.link} target="_blank" rel="noopener noreferrer">{card.title}</a>;
  };

  function navigate(link) {
    if (link == null || link == '') {
      return
    }

    if (link.indexOf('/') === 0) {
      history.push(link)
    } else {
      window.location = link
    }
  }

  return (
    <Container className="contentWrapper">
      { children }

      {data.map((list, i1) => (
        <Row key={i1}>
          {list.map((card, i2) => (
            <Col key={i2} className="mb-3" xs={12} md={12 / list.length}>
              <Card bg="light" className={{'wlink': card.link !== '' && card.link !== null}} onClick={() => navigate(card.link)}>
                <Card.Body className="flex-column d-flex">
                  <Card.Text>{card.text}</Card.Text>
                  <Card.Title className="mt-auto">
                    {linkEl(card)}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
}

export default CardListContainer;
