import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';

import AddressBlock from "./AddressBlock";

function FullWidthButtonContainer(props) {
  const [show, setShow] = React.useState(false);

  const handleCancel  = () => {
    setShow(false)
    if (props.hasOwnProperty("onQuit")) {
      props.onQuit();
    }
  };
  const handleShow    = () => setShow(true);

  return (
    <Container fluid className="buttonSectionWrapper">
      <Button onClick={handleShow}>Prenez rendez-vous avec nous</Button>

      <Modal show={show} onHide={handleCancel} size="lg">
        <Modal.Header closeButton >
          <Modal.Title>Prenez rendez-vous avec nous</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{"text-align": "center"}}>
          <AddressBlock />
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default FullWidthButtonContainer;
