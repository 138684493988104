import React from 'react';

function AddressBlock() {
  return (
    <div className="addressBlockSection">
      <h3>Autos Motos Quiblier</h3>
      <p>Pascal Mosisca - Pierre-Alain Morard</p>
      <p>Route du Stand 17, 1260 Nyon</p>
      <p><a href="tel:+41223613369">Tél: 022 361 33 69</a></p>
      <p><a href="tel:+41223617525">Fax: 022 361 75 25</a></p>
      <p><a href="mailto:autos-motosquiblier@bluewin.ch">autos-motosquiblier@bluewin.ch</a></p>
    </div>
  );
}

export default AddressBlock;
