import React from 'react';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';

import CardListContainer from '../../commons/ui/CardListContainer';

const data = [
  [
    {
      title: "Autos",
      text: "Retrouvez notre sélection de voitures d’occasion sur Anibis.ch",
      link: "https://www.anibis.ch/fr/advertlist.aspx?mid=48290"
    },
    {
      title: "Motos",
      text: "Retrouvez notre sélection de motos d’occasion sur Motoscout24",
      link: "https://www.motoscout24.ch/fr/ip/autos-motos-quiblier-sarl-1260-nyon/vehicles?accountid=64557"
    },
    {
      title: "Scooters",
      text: "Retrouvez notre sélection de scooters d’occasion sur Motoscout24",
      link: "https://www.motoscout24.ch/fr/ip/autos-motos-quiblier-sarl-1260-nyon/vehicles?accountid=64557"
    }
  ]
];

function SecondHand() {
  return (
    <div>
      <FullWidthBgImageContainer bgClassname="bg-img-occasions">
        <div className="contentTitleWrapper">
          <h1>Occasions</h1>
          <h2>Vous trouverez ci-dessous tous nos véhicules d’occasion disponibles à la vente. Ceci comprend les autos, motos et scooters.</h2>
        </div>
      </FullWidthBgImageContainer>

      <CardListContainer data={data}>
        <h3>Nos véhicules d'occasions</h3>
        <p>Toutes les occasions sont listées sur Anibis ainsi que sur Motoscout24.</p>
        <p>N’hésitez pas à nous contacter directement depuis ces plateformes, ou à prendre contact avec nous via l’adresse e-mail ou le numéro de téléphone mentionnées ci-dessous.</p>
      </CardListContainer>

      <FullWidthButtonContainer />

    </div>
  );
}

export default SecondHand;
