import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import AddressBlock from '../../commons/ui/AddressBlock';
import HoursBlock from '../../commons/ui/HoursBlock';

function ContactAddressSection() {
  return (
    <Container className="contentWrapper">
      <Row>
        <Col>
          <AddressBlock />
        </Col>
        <Col>
          <HoursBlock />
        </Col>
      </Row>
    </Container>
  );
}

export default ContactAddressSection;
