import React from 'react';
import { Container } from 'react-bootstrap';

import CardListContainer from '../../commons/ui/CardListContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';
import AboutUs from '../../commons/ui/AboutUs';

import HomeCarousel from './HomeCarousel';
import CardsData from './data/CardsData';


function Home() {
  return (
    <div>
      <div className="bg-dark">
        <HomeCarousel />
      </div>
      <Container className="home-container">
        <h3>Achat autos, motos & scooters</h3>
        <p>Nous rachetons vos autos, motos et scooters au meilleur prix, contactez-nous pour un devis</p>
      </Container>
      <FullWidthButtonContainer />
      <CardListContainer data={CardsData} />
      <AboutUs className="contentWrapper bg-img" />
    </div>
  );
}

export default Home;
