import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import AddressBlock from '../ui/AddressBlock';
import FooterNav from './FooterNav';

import './footer.css';

function PageHeader(props) {

  return (
    <footer>
      <Container>
        <Row className="text-center" >
          <Col className="text-md-left" xs="12" sm="12" md="5">
            <AddressBlock />
          </Col>
          <Col className="text-md-right" xs="12" sm="12" md="7">
            <FooterNav />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default PageHeader;
