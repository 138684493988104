import React from 'react';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';
import CardListContainer from '../../commons/ui/CardListContainer';

import CardsData from './data/Cards';

function Partners() {
  return (
    <div>
      <FullWidthBgImageContainer bgClassname="bg-img-partners">
        <div className="contentTitleWrapper">
          <h1>Partenaires</h1>
          <h2>Commandez vos pneus chez un de nos partenaires et faites-les livrer chez nous.</h2>
        </div>
      </FullWidthBgImageContainer>

      <CardListContainer data={CardsData}>
        <h3>Nos partenaires</h3>
        <p>Au travers des liens ci-dessous, vous pourrez acquérir vos pneus chez un de nos partenaires et les faire directement livrer chez nous.</p>
        <p>N’hésitez pas à prendre contact avec nous via l’adresse e-mail ou le numéro de téléphone mentionnées ci-dessous.</p>
      </CardListContainer>

      <FullWidthButtonContainer />
    </div>
  );
}

export default Partners;
