import React from 'react';
import { Col, Card, Row } from 'react-bootstrap';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';

function AboutUs() {
  return (
    <FullWidthBgImageContainer bgClassname="bg-img-apropos">
      <Card>
        <Card.Body>
          <Card.Title>A propos du Garage Quiblier</Card.Title>
          <Row>
            <Col xs={12} md={6}>
              <Card.Text>
                Cela fait maintenant plus de 10 ans que nous sommes à la tête du garage Quiblier, avec un seul objectif en tête ; garantir votre sécurité ainsi que votre confort de conduite.
              </Card.Text>
              <Card.Text>
                Bon nombre d’accidents routiers ou d’autres situations inconvénientes pourraient être évitées au travers d’un entretien optimal de votre véhicule. Nous serons toujours à votre disposition pour vous conseiller par rapport à toutes préoccupations que vous pourriez avoir quant à la bonne marche de votre voiture, moto ou scooter.
              </Card.Text>
            </Col>

            <Col  xs={12} md={6}>
              <Card.Text>
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2752.3490465040354!2d6.224231932214273!3d46.382301750624805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c5d2a56f66811%3A0x56c2f6d2ce8e53a3!2sAutos-Motos%20Quiblier%20sarl!5e0!3m2!1sfr!2sch!4v1574958870109!5m2!1sfr!2sch" style={{
                  "width": "100%",
                  "height": "300px"
                }} frameBorder="0"  allowFullScreen="" />
              </Card.Text>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </FullWidthBgImageContainer>
  );
}

export default AboutUs;
