import React from 'react';

import img_carousel0 from '../../images/carousel0_lambretta_scooters.jpg';
import img_carousel0_540 from '../../images/carousel0_lambretta_scooters_540.jpg';

import img_carousel1 from '../../images/carousel1_niu_scooters.jpg';
import img_carousel1_540 from '../../images/carousel1_niu_scooters_540.jpg';

import img_carousel2 from '../../images/carousel2_autosource_import_car.jpg';
import img_carousel2_540 from '../../images/carousel2_autosource_import_car_540.jpg';

import img_carousel3 from '../../images/carousel3_motos_garage_quiblier.jpg';
import img_carousel3_540 from '../../images/carousel3_motos_garage_quiblier_540.jpg';

import img_carousel4 from '../../images/carousel4_service_pneu_partenaires.jpg';
import img_carousel4_540 from '../../images/carousel4_service_pneu_partenaires_540.jpg';

import {Card, Carousel, Container} from 'react-bootstrap';

function HomeCarousel() {

  return (
    <Container>
      <Carousel className="homeCarousel shadow" fade={true} pause={false}>

      <Carousel.Item>
        <img className="d-block img-fluid mx-auto" src={img_carousel0_540} srcSet={`${img_carousel0_540} 980w 2x, ${img_carousel0} 1100w 1x`} alt="Gamme Scooters Lambrettas"/>
        <Carousel.Caption>
          <Card className="bg-dark-opacity shadow" onClick={() => window.location = "/ventes"}>
            <Card.Body>
              <Card.Title>Nouvelle gamme de véhicules Lambrettas</Card.Title>
              <Card.Text>Découvrez la nouvelle gamme de véhicules Lambrettas, disponibles en vente ainsi qu’en location</Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Caption>
      </Carousel.Item>

        <Carousel.Item>
          <img className="d-block img-fluid mx-auto" src={img_carousel1_540} srcSet={`${img_carousel1_540} 980w 2x, ${img_carousel1} 1100w 1x`} alt="Gamme Scooters Niu"/>
          <Carousel.Caption>
            <Card className="bg-dark-opacity shadow" onClick={() => window.location = "/ventes"}>
              <Card.Body>
                <Card.Title>Vente de Scooters électriques neufs</Card.Title>
                <Card.Text>Découvrez la gamme de véhicules électriques NIU et laissez nous prendre en charge la commande ainsi que la livraison. Pour plus d’informations n’hésitez à nous appeler</Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block img-fluid mx-auto" src={img_carousel2_540} srcSet={`${img_carousel2_540} 980w 2x, ${img_carousel2} 1100w 1x`}  alt="Autosource Importation Véhicules Neufs"/>
          <Carousel.Caption>
            <Card className="bg-dark-opacity shadow" onClick={() => window.location = "/ventes"}>
              <Card.Body>
                <Card.Title>Importation directe d’automobiles neuves</Card.Title>
                <Card.Text>Vous songez à acquérir une voiture neuve au meilleur prix ? Commandez-la à un prix jusqu’à 30% inférieur au prix catalogue (importation).</Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block img-fluid mx-auto" src={img_carousel3_540} srcSet={`${img_carousel3_540} 980w 2x, ${img_carousel3} 1100w 1x`} alt="AutoScoot24 Anibis Vente Véhicules Occasions"/>
          <Carousel.Caption>
            <Card className="bg-dark-opacity shadow" onClick={() => window.location = "/occasions"}>
              <Card.Body>
                <Card.Title>Vente de véhicules d’occasions</Card.Title>
                <Card.Text>Que vous cherchiez une moto, une voiture ou un scooter d’occasion, n’hésitez pas à explorer nos offres.</Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block img-fluid mx-auto" src={img_carousel4_540} srcSet={`${img_carousel4_540} 980w 2x, ${img_carousel4} 1100w 1x`} alt="AutoScoot24 Anibis Vente Véhicules Occasions"/>
          <Carousel.Caption>
            <Card className="bg-dark-opacity shadow" onClick={() => window.location = "/partenaires"}>
              <Card.Body>
                <Card.Title>Pensez à vos pneus neige !</Card.Title>
                <Card.Text>Dès le 1er novembre, ceux-ci sont obligatoire en France. Commandez-les chez un de nos partenaires et faites-les livrer directement chez nous. </Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>
    </Container>
  );
}

export default HomeCarousel;
