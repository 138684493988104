import React from 'react';
import { Link } from 'react-router-dom';

import Routes from '../Routes';

function FooterNav() {
  return (
    <div className="footer-nav-block">

      <div className="copyrigth">
        {Routes.map((route, i) => (
          <Link key={i} to={route.path}>{route.label}</Link>
        ))}
      </div>

      <div>
        <p>© 2020 - 2021 Garage Autos Motos Quiblier - Tous droits réservés</p>
      </div>
    </div>
  );
}

export default FooterNav;
