import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from './commons/header/Header';
import Footer from './commons/footer/Footer';
import Routes from './commons/Routes';

import './App.css';



function App() {
  return (
    <Router>
      <Header />

      <Switch>
        {Routes.map((route, i) => (
          <Route key={i} path={route.path} exact component={route.component} />
        ))}
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
