import React from 'react';

import FullWidthBgImageContainer from '../../commons/ui/FullWidthBgImageContainer';
import FullWidthButtonContainer from '../../commons/ui/FullWidthButtonContainer';

import CardListContainer from '../../commons/ui/CardListContainer';

const data = [
  [
    {
      title: "Nos motos",
      text: "Retrouvez toutes nos motos disponibles à la location",
      link: "https://www.motoscout24.ch/fr/ip/autos-motos-quiblier-sarl-1260-nyon/vehicles?accountid=64557"
    },
    {
      title: "Nos scooters",
      text: "etrouvez toutes nos scooters disponibles à la location",
      link: "https://www.motoscout24.ch/fr/ip/autos-motos-quiblier-sarl-1260-nyon/vehicles?accountid=64557"
    }
  ]
];

function Rental() {
  return (
    <div>
      <FullWidthBgImageContainer bgClassname="bg-img-rental">
        <div className="contentTitleWrapper">
          <h1>Location</h1>
          <h2>Ici vous pourrez louer motos ou scooters pour la durée qui vous convient. Plusieurs modèles sont à votre disposition en tout temps.</h2>
        </div>
      </FullWidthBgImageContainer>

      <CardListContainer data={data}>
        <h3>Nos véhicules de location</h3>
        <p>En suivant les liens ci-dessous, vous trouverez nos véhicules classés par catégorie.</p>
        <p>N’hésitez pas à prendre contact avec nous via l’adresse e-mail ou le numéro de téléphone mentionnées ci-dessous.</p>
      </CardListContainer>

      <FullWidthButtonContainer />

    </div>
  );
}

export default Rental;
