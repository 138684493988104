import React from 'react';
import { Container, Navbar, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Routes from '../Routes';
import './header.css';

function Header(props) {

  let location = useLocation();

  return (
    <header className="shadow">

      <div className="header-nav-wrapper">

        <Container>
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              <img
                src="/logo.png"
                width="200"
                height="103"
                alt="Garage Quiblier logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto" activeKey={location.pathname}>
                {Routes.map((route, i) => (
                  <Nav.Link key={i} href={route.path}>{route.label}</Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </header>
  );
}

export default Header;
